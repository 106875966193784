@import "../../common/commonStyles.module.scss";

.sectionWrapper {
  position: relative;
  z-index: 8;
  background-color: $white;
  padding-bottom: 250px;

  @media screen and (max-width: 415px) {
    padding-bottom: getVW(250px);
  }
}

.content {
  max-width: 500px;
  margin: 0 auto;
  padding: 0 29px 200px 29px;
  box-sizing: border-box;
  text-align: center;
  font-family: "Jost", sans-serif;
  color: $middleGrey;

  @media screen and (max-width: 800px) {
    max-width: 100%;
  }

  @media screen and (max-width: 415px) {
    padding: 0 getVW(29px) getVW(200px) getVW(29px);
    font-size: getVW(18px);
  }
}

.title {
  padding-top: 120px;
  padding-bottom: 60px;

  @media screen and (max-width: 415px) {
    padding-top: getVW(120px);
    padding-bottom: getVW(60px);
  }
}

.sectionTitle {
  margin-top: 36px;
  font-size: 22px;

  @media screen and (max-width: 415px) {
    margin-top: getVW(36px);
    font-size: getVW(22px);
  }
}

.obertoneLink {
  text-decoration: none;
  color: $darkGrey;
}

.mainSection {
  font-size: 20px;
  line-height: 28px;

  @media screen and (max-width: 415px) {
    font-size: getVW(14px);
  }
}

.section {
  margin-top: 10px;
  font-size: 14px;
  line-height: 22px;

  @media screen and (max-width: 415px) {
    font-size: getVW(14px);
  }
}

.section {
  padding-top: 5px;
  font-size: 14px;
  line-height: 22px;

  @media screen and (max-width: 415px) {
    font-size: getVW(14px);
  }
}

.contactsSectionTitle {
  margin-top: 0;
  margin-bottom: 72px;

  font-size: 48px;
  padding-top: 72px;
  padding-bottom: 30px;

  @media screen and (max-width: 1200px) {
    padding-top: 50px;
  }

  @media screen and (max-width: 600px) {
    font-size: 36px;
    padding-top: 40px;
  }

  @media screen and (max-width: 414px) {
    padding: 20px 0;
  }
}

.albumCover {
  width: 100%;
  height: auto;

  margin-bottom: 28px;
}

.assetsLink {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 120px;
  margin-top: 36px;
  margin-bottom: 64px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: $white;
  background-color: $pink;
  border-radius: 2px;
  opacity: 0.9;
  font-family: "Jost", sans-serif;
  font-size: 36px;
  line-height: 32px;
  transition: opacity 0.25s;

  &:hover {
    opacity: 1;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    height: 100px;
    font-size: getVW(21px);
    line-height: getVW(36px);
  }
}

.assetsLinkSmallText {
  font-size: 24px;

  @media screen and (max-width: 500px) {
    font-size: getVW(16px);
  }
}

.socialLinks {
  a {
    opacity: 0.9;

    &:hover {
      opacity: 1;
    }
  }
}
