@import "../../common/commonStyles.module.scss";

.sectionWrapper {
  //   z-index: 4;
  z-index: 6;
  background-color: $white;
  padding-bottom: 1px;
}

.content {
  color: $middleGrey;
}

.title {
  padding-top: 150px;
}

.program {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 93px;
  font-family: "Jost", sans-serif;

  &:last-child {
    margin-bottom: 180px;
  }
}

.programSection {
  width: 500px;
  line-height: 26px;
  font-size: 18px;
  color: #49484b;

  @media screen and (max-width: 1559px) and (min-width: 1335px) {
    width: 420px;
  }

  @media screen and (max-width: 500px) {
    width: calc(100% - 25px);
    margin-left: 12px;
  }

  b {
    word-break: break-all;
  }
}

.programPictureSection {
  @media screen and (max-width: 1335px) {
    display: none;
  }

  @media screen and (max-width: 1559px) {
    width: 350px;
  }
}

.programPicture {
  width: 100%;
  height: auto;
}

.programTitle {
  font-size: 36px;
  line-height: 40px;
}

.programDescrciption {
  line-height: 20px;
}

.programDescrciptionFooter {
  margin: 26px 0;
}

.dataprotectionText {
  
  text-align: center;
  padding-bottom: 50px;

  a:link, a:visited {
    opacity: 0.8;
    color: #49484b;
    text-decoration: underline;
    display: inline-block;
  }
  a:hover {
    opacity: 1;
    color: #49484b;
    text-decoration: underline;
    display: inline-block;
  }

}