@import "../../common/commonStyles.module.scss";

.sectionWrapper {
  z-index: 6;
  background-color: #138999;
}

.content {
  max-width: 500px;
  margin: 0 auto;
  padding: 0 29px 109px 29px;
  text-align: center;
  color: $white;
  font-family: "Jost", sans-serif;
  font-size: 20px;
  line-height: 1.4;

  @media screen and (max-width: 800px) {
    max-width: 100%;
  }

  @media screen and (max-width: 415px) {
    padding: 0 getVW(29px) getVW(109px) getVW(29px);
    font-size: getVW(20px);
  }
}

.title {
  color: $white;
}

.italicText {
  margin: 0;
  font-family: "Jost", sans-serif;
  font-style: italic;
  letter-spacing: 0.005em;
  font-size: 16px;
  font-weight: 200;
  line-height: 1.6;

  @media screen and (max-width: 415px) {
    font-size: getVW(21px);
  }
}

.subtitle {
  margin-bottom: 10px;
  font-size: 24px;

  @media screen and (max-width: 415px) {
    font-size: getVW(24px);
  }
}

.linkButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  box-sizing: border-box;
  border: 2px solid $white;
  font-size: 36px;
  font-weight: 300;
  text-decoration: none;
  color: $white;
  white-space: nowrap;

  @include buttonGlow();

  @media screen and (max-width: 415px) {
    height: getVW(80px);
    font-size: getVW(36px);
  }
}

.ticketsPageLink {
  width: 500px;
  margin: 0 auto 28px auto;
  padding: 0 18px;
  border-width: 3px;
  padding-bottom: 6px;

  @media screen and (max-width: 799px) {
    width: 360px;
  }

  @media screen and (max-width: 415px) {
    width: 100%;
    padding: 0 getVW(18px);
    border-width: 2px;
  }
}

.directionsLink {
  display: flex;
  justify-content: center;
  width: 400px;
  margin: 0 auto;
  font-size: 30px;
  padding-bottom: 2px;

  > div {
    display: flex;
    align-items: center;

    > span {
      padding-bottom: 3px;
      font-weight: 400;
    }
  }

  svg {
    height: 40px;
    width: auto;
    margin-left: 30px;
  }

  @media screen and (max-width: 799px) {
    width: 330px;
  }

  @media screen and (max-width: 415px) {
    width: 100%;
    font-size: getVW(30px);

    svg {
      height: getVW(40px);
      margin-left: getVW(30px);
    }
  }
}

.whiteLink {
  text-decoration: none;
  color: $white;
}

.whiteLinkunderlined {
  text-decoration: none;
  color: $white;
}

a:hover {
  text-decoration: underline;
}


.directionsTitle {
  margin: 72px 0 28px 0;
  font-size: 30px;
  font-weight: 200;
  line-height: 1.2;

  @media screen and (max-width: 415px) {
    margin: getVW(72px) 0 getVW(28px) 0;
    font-size: getVW(30px);
  }
}

