.aboutTeamContainer {
  padding-top: 110px;
  font-family: "Jost", sans-serif;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.25em;
  color: #49484b;

  .aboutTeamSectionHeader {
    margin-top: 63px;
    margin-bottom: 14px;
    font-weight: 700;
    color: #aeadb2;
    text-align: right;

    @media screen and (min-width: 1280px) {
      position: absolute;
      transform: translateX(-100%);
      left: 0;
      top: 0;
      margin: 0;
      font-size: 48px;
      margin-left: -19px;
      margin-top: -14px;
      line-height: normal;
    }
  }
}

.clubLink {
  @media screen and (max-width: 1400px) {
    max-width: 420px;
  }
}

.aboutTeamSectionFooter {
  font-size: 14px;
}

.teamContainer {
  text-align: center;
  padding: 0 20px;
}

.teamPicture {
  width: 100%;
  max-width: 830px;
}

.aboutPageRoot {
  position: relative;
  height: 100vh;
  overflow: auto;
}
