@import "../../common/commonStyles.module.scss";

.sectionWrapper {
  height: auto;
  background: #fff;
  z-index: 2;
  min-height: 100vh;
}

.musicians {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 1230px;
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    width: 820px;
  }

  @media screen and (max-width: 820px) {
    width: 360px;
  }

  @media screen and (max-width: 415px) {
    width: 100%;
  }
}
