.imagesSlider {
  width: 100%;
  overflow: hidden;
  opacity: 0.8;

  > div {
    white-space: nowrap;
  }
}

.noOffset {
  transform: translateX(0);
}
