@import "../../common/commonStyles.module.scss";

.accordion {
  width: 100%;
  max-width: 500px;
  margin: 63px auto 32px auto;
  padding: 0 12px 0 13px;
  position: relative;
  box-sizing: border-box;

  p {
    margin: 10px 0;
  }

  @media screen and (min-width: 1280px) {
    margin: 165px auto 96px auto;
  }
}

.expandButton {
  font-weight: 700;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
