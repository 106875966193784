$white: #fffefa;
$lightGrey: #646366;
$middleGrey: #49484b;
$darkGrey: #1c1a1e;
$blue: #5b72c9;
$blue1: #4753a3;
$pink: #e6196a;

@function getVW($target, $screenWidth: 415) {
  $vwContext: ($screenWidth * 0.01) * 1px;
  @return ($target / $vwContext) * 1vw;
}

@mixin buttonGlow() {
  box-shadow: 0 0 9px 0 rgba(255, 254, 250, 0.5);
}

@mixin withBorderRadius() {
  border-radius: 2px;
}

.sectionWrapper {
  position: relative;
  box-sizing: border-box;
  z-index: 1;

  // @media screen and (max-width: 800px) and (min-width: 540px) {
  //   padding-left: 138px;
  // }
}

.title {
  max-width: 800px;
  box-sizing: border-box;
  padding: 109px 29px 72px 29px;
  margin: 0 auto;
  font-family: "Jost", sans-serif;
  font-size: 48px;
  font-weight: 400;
  white-space: nowrap;
  line-height: 1;
  color: $darkGrey;
  text-align: center;

  @media screen and (max-width: 800px) {
    max-width: 100%;
    white-space: normal;
  }

  @media screen and (max-width: 415px) {
    padding: getVW(109px) getVW(29px) getVW(72px) getVW(29px);
    font-size: getVW(48px);
  }
}

.titleWithBackground {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 51px 0;
  box-sizing: border-box;
  color: $white;
  background: #138999;
  font-size: 60px;
  font-weight: 400;
  font-family: "Jost", sans-serif;
  text-align: center;

  @media screen and (max-width: 1200px) {
    font-size: 50px;
    padding: 30px 0;
  }

  @media screen and (max-width: 500px) {
    font-size: 40px;
    padding: 20px 0;
  }

  @media screen and (max-width: 380px) {
    font-size: 30px;
    padding: 10px 0;
  }
}

.withRoundedBorder {
  border-radius: 1px;
}
