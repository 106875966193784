@import "../../common/commonStyles.module.scss";

.sectionWrapper {
  position: relative;
  z-index: 7;
  background: url("../../images/background_4.jpg") no-repeat center;
  background-size: cover;
  height: 100vh;
}

.titleWithBackground {
  background-color: #aeadb2;
  font-size: 48px;
  padding-top: 72px;
  padding-bottom: 30px;

  @media screen and (max-width: 1200px) {
    padding-top: 50px;
  }

  @media screen and (max-width: 600px) {
    font-size: 36px;
    padding-top: 40px;
  }

  @media screen and (max-width: 414px) {
    padding: 20px 0;
  }

  @media screen and (max-width: 380px) {
    font-size: 30px;
  }
}

.aboutSectionContent {
  display: flex;
  justify-content: center;
}

.obertoneLink {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 120px;
  margin-top: 40vh;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: $white;
  background-color: #e6d430;
  border: 1px solid $white;
  border-radius: 2px;
  opacity: 0.9;
  font-family: "Jost", sans-serif;
  font-size: 36px;
  line-height: 32px;
  transition: opacity 0.25s;

  &:hover {
    opacity: 1;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    height: 100px;
    font-size: getVW(21px);
    line-height: getVW(36px);
    margin-right: 10px;
    margin-left: 10px;
  }
}

.obertineLinkSmallText {
  font-size: 24px;

  @media screen and (max-width: 500px) {
    font-size: getVW(16px);
  }
}
